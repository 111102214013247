import { schemaColors } from 'misc/recoil/atoms';
import { useLocation } from 'react-router-dom';

import { HeaderIcon, HeaderIconRelative } from './styles';

export default function Header() {
  const path = useLocation().pathname;
  return (
    <>
      <HeaderIcon>
        <h1 style={{margin:0, color: schemaColors[6]}}>AUTOMOTIVE <br/> SKILLS ECOSYSTEM</h1>
      </HeaderIcon>
      <HeaderIconRelative>
        <h1 style={{margin:0, color: schemaColors[6]}}>AUTOMOTIVE <br/> SKILLS ECOSYSTEM</h1>
      </HeaderIconRelative>
    </>
  );
}
